document.addEventListener('DOMContentLoaded', () => {
    const dropDown = document.querySelector('.wpml-ls-item-legacy-dropdown');

    dropDown && dropDown.addEventListener('click', () => {
        const subMenu = dropDown?.querySelector('.wpml-ls-sub-menu');

        dropDown?.classList.toggle('open');
        subMenu?.classList.toggle('open');
    });
})


